import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/blogpost.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`In Javascript, all values can be type coerced to be evaluated in a boolean expression. So what this means in practice is that every data type has some notion of being true or false. Integers and floats are "false" if their value is 0. Strings are "false" if they have no characters, as are the values null, undefined, and NaN. Any initialized structure or object is "true", even the empty ones like [] and {}. Over time, people came to use the words "truthiness" and "falsiness" to describe this type of behavior.`}</p>
    <blockquote>
      <p parentName="blockquote"><em parentName="p">{`Side note: This harkens back to the days of C, when there were no booleans built into the language. In C, every value has an implicit "truthiness", determined by whether or not it's equal to bitwise 0. Comparisons return 1 for true and 0 for false.`}</em></p>
    </blockquote>
    <p>{`This concept allows us to do cool and convenient things to make our code more succinct. For example, instead of `}<inlineCode parentName="p">{`while (x > 0) {...}`}</inlineCode>{`, one could just say `}<inlineCode parentName="p">{`while (x) {...}`}</inlineCode>{`. Or instead of `}<inlineCode parentName="p">{`if (someObject != null) {...}`}</inlineCode>{`, just `}<inlineCode parentName="p">{`if (someObject) {...}`}</inlineCode>{`. You see this type of test everywhere, and this is one of the many constructs that Javascript has that allows for amazingly flexible and fast code writing, and lets you to feel like a ninja while writing (for better or for `}<a parentName="p" {...{
        "href": "https://javascript.info/ninja-code"
      }}>{`worse`}</a>{`).`}</p>
    <p>{`"Okay", you say, "okay cool, that makes sense". Yes it sure does. But, just go to your browser (I tried this on Opera, Chrome, Edge, and Firefox), open up the developer console, and type `}<inlineCode parentName="p">{`document.all`}</inlineCode>{`. You might notice the expression return type comes back as the aptly titled `}<inlineCode parentName="p">{`HTMLAllCollection`}</inlineCode>{` which contains all the elements on the current document. Log it and you'll see the collection returned. Assign it to a variable and you'll see the expression return the collection again. "Cool, a document collection object", you think.`}</p>
    <p>{`Okay, now do:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`typeof document.all;
`}</code></pre>
    <p>{`What did you get? Surprised? `}</p>
    <h3>{`The Cold Hard Truth (or not so much)`}</h3>
    <p>{`The MDN docs contains a table with a `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Glossary/Falsy"
      }}>{`complete list`}</a>{` of "falsy" values. Looking through the table, you see all the usual suspects: `}<inlineCode parentName="p">{`false`}</inlineCode>{`, `}<inlineCode parentName="p">{`0`}</inlineCode>{`, `}<inlineCode parentName="p">{`-0`}</inlineCode>{`, empty strings, BigInt's `}<inlineCode parentName="p">{`0n`}</inlineCode>{`, and so on. And then at the bottom, there's `}<inlineCode parentName="p">{`document.all`}</inlineCode>{` sitting there by itself. The right side of the table offers up: `}</p>
    <blockquote>
      <p parentName="blockquote"><em parentName="p">{`"Objects are falsy if and only if they have the [`}{`[IsHTMLDDA]`}{`] internal slot. That slot only exists in document.all and cannot be set using JavaScript."`}</em>{` `}</p>
    </blockquote>
    <p>{`Following the link on [`}{`[IsHTMLDDA]`}{`] takes you to the offical ECMAscript `}<a parentName="p" {...{
        "href": "https://tc39.es/ecma262/#sec-IsHTMLDDA-internal-slot"
      }}>{`specification`}</a>{` which opaquely declares: `}</p>
    <blockquote>
      <p parentName="blockquote"><em parentName="p">{`"An [`}{`[IsHTMLDDA]`}{`] internal slot may exist on host-defined objects."`}</em>{` `}</p>
    </blockquote>
    <p>{`Well that doesn't help at all...`}</p>
    <p>{`In essence, ES2015 uses the construct of internal slots to set the behavior of different objects by attaching values and methods to named fields. The spec says that an [`}{`[IsHTMLDDA]`}{`] internal slot may exist on host-defined objects, which are any objects provided by the host environment (like `}<inlineCode parentName="p">{`window`}</inlineCode>{`, `}<inlineCode parentName="p">{`document`}</inlineCode>{`, and family in a browser, or `}<inlineCode parentName="p">{`module`}</inlineCode>{` in Node). But then it notes that `}<em parentName="p">{`"...implementations should not create any with the exception of document.all."`}</em>{` So why is this field the only outlier here? By now, you may have noticed that part of the field name "HTMLDDA" looks suspiciously close to an abbreviation for "HTML Document.All". Is it that this is a shim to make document.all act in a different way for some reason? `}</p>
    <p>{`And indeed, the reason behind all this craziness is a need for compatibility. The `}<inlineCode parentName="p">{`document.all`}</inlineCode>{` field was provided in really old browsers as a way to modify and access parts of the document, but it was superceded by more modern ways of doing things like `}<inlineCode parentName="p">{`document.getElementById()`}</inlineCode>{`. Older webpages would often test `}<em parentName="p">{`first`}</em>{` for `}<inlineCode parentName="p">{`document.all`}</inlineCode>{` to see if they could use it as their interface, using the most succinct test possible, courtesy of Javascript's coercive typing. In more modern browsers, this meant that better ways of doing things were being passed up in favor of `}<inlineCode parentName="p">{`document.all`}</inlineCode>{` because it was simply asked for first. `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`if (document.all) {
    // use document.all
} else if (betterInterfaceExists) {
    // we never make it here...
}
`}</code></pre>
    <p>{`Now this could simply be solved if developers tested for the other interface first, but there were already so many webpages testing for `}<inlineCode parentName="p">{`document.all`}</inlineCode>{` first that wouldn't be fixed that way unless the developers went and switched it themselves, but getting that to happen would have been pretty much impossible.`}</p>
    <p>{`So the solution that many browsers implemented (and that was worked into ECMAScript with the [`}{`[IsHTMLDDA]`}{`] slot) was to make `}<inlineCode parentName="p">{`document.all`}</inlineCode>{` falsy by `}<a parentName="p" {...{
        "href": "https://html.spec.whatwg.org/multipage/common-dom-interfaces.html#htmlallcollection"
      }}>{`modifying`}</a>{` its `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Equality_comparisons_and_sameness"
      }}>{`abstract equality`}</a>{` and `}<a parentName="p" {...{
        "href": "https://tc39.es/ecma262/#sec-toboolean"
      }}>{`toBoolean`}</a>{` behaviors. That way, if any script tested to see if `}<inlineCode parentName="p">{`document.all`}</inlineCode>{` existed before using it, the script would be told that it doesn't, and it would pass through.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`if (document.all) {
    // since document.all is falsy,
    // this code doesn't execute
} else if (document.getElementById) {
    // so we use this
}
`}</code></pre>
    <p>{`And for browsers which weren't polite enough to check, the functionality was still there for them to use. You can still see that in your browser; although it evaluates to undefined and coerces to false, if you just type `}<inlineCode parentName="p">{`document.all`}</inlineCode>{` the expression will evaluate to an `}<inlineCode parentName="p">{`HTMLAllCollection`}</inlineCode>{`. You could detect it with `}<inlineCode parentName="p">{`if ("all" in document)`}</inlineCode>{` since they didn't fix that because no one really did it that way. It's important to note that this field has been deprecated ever since the release of HTML5 and is not dependable or guaranteed to exist. In most modern browsers, it's just internally shimmed to a different interface so it can be used like normal, but it's definitely not the best or safest option.`}</p>
    <p>{`Is it a big deal? Unless you're trying to write Javascript with support for early IE and Netscape Navigator in mind, it won't affect your day-to-day in the slightest. It's just an artifact of an older age tucked up away from the modern code world for legacy scripts to grab and use if necessary. But it is an interesting how widespread use of a common feature test built up such a problem that the behavior, and eventually the fundamentals, of the language had to be changed just to solve the problem. It's a fix that wormed its way to the heart of Javascript and when all was said and done, truth was left a little less sure. `}</p>
    <blockquote>
      <p parentName="blockquote"><em parentName="p">{`"The truth is rarely pure and never simple"`}</em>{` - Oscar Wilde`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      