import React from "react"

import style from "./headers.module.css"

export const SectionHeader = (props) => {

    const newProps = JSON.parse(JSON.stringify(props));

    newProps.className += ` text-sans-serif-display text-bold`;

    return (
        <h2 {...newProps}/>
    )
}

export const SectionMinorHeader = (props) => {
    const newProps = JSON.parse(JSON.stringify(props));

    newProps.className += ` text-sans-serif-display text-bold`;

    return (
        <h4 {...newProps}/>
    )
}
