import React, { useEffect, useState } from "react"
import { MDXProvider } from "@mdx-js/react"
import { Link } from "gatsby"
import layoutStyle from "./style/blogpost.module.css"
import Frame from "../components/frame"
import CodeBlock from "../components/codeblock"
import RendererBlock from "../components/rendererblock"
import { SectionHeader, SectionMinorHeader } from "../components/elements/headers"
import { months } from "../../static/datetime"

const Note = (props) => {
    return (
        <div className={layoutStyle.note}>
            <div style={{ 
                padding: '2px 15px 0px', 
                paddingLeft: '10px', 
                borderLeft: '3px solid var(--bg-dark)', 
                background: 'inherit'
            }}>
                {props.children}
            </div>
        </div>
    )
}

const InlineImage = (props) => {

    const newProps = {...props};

    if (!newProps.style) newProps.style = {};

    newProps.style.maxWidth = '100%'

    return (
        <img {...newProps}></img>
    )
}

export default (props) => {

    // setting up the Section Box

    const [ titleIds, setTitleIds ] = useState([]);

    const [ sectionBox, setSectionBox ] = useState(null);

    const SectionBox = () => {
        setSectionBox(
            <div>
                {titleIds.map(ti => <Link to={`#${ti[1]}`}>{ti[0]}</Link>)}
            </div>
        )
    }


    const newTabAnchor = (props) => {
        return <a {...props} target='_blank'/>
    }

    const shortcodes = { Link, 
        blockquote: Note,
        code: RendererBlock, 
        a: newTabAnchor,
        h2: SectionHeader,
        h4: SectionMinorHeader,
        img: InlineImage,
    }

    const paths = props.pageContext;

    const seriesTitle = (paths.series_title)
        ? <h4 className={`${layoutStyle.seriestitle}`}>{paths.series_title}</h4>
        : null;

    const d = new Date(paths.frontmatter.date);
    const dateString = `${months[d.getUTCMonth()]} ${d.getUTCDate()}, ${d.getUTCFullYear()}`;
    const date = props.pageContext.frontmatter.date ?
        <h4 className={`${layoutStyle.date}`}>{dateString}</h4> :
        null;

    const prevlink = paths.prev_slug ? 
    <Link to={paths.prev_slug} class={`${layoutStyle.link}`}><span style={{fontFamily: 'sans-serif'}}>&larr;</span>  {paths.prev_title}</Link> : <p> </p>;

    const nextlink = paths.next_slug ? 
    <Link to={paths.next_slug} class={`${layoutStyle.link}`}>{paths.next_title} <span style={{fontFamily: 'sans-serif'}} >&rarr;</span></Link> : <p> </p>;

    return (
        <Frame>
            <div className={`${layoutStyle.container}`}>
                <div className={`${layoutStyle.headerbox} text-sans-serif-display`}>
                    {seriesTitle}
                    <h1 className={`${layoutStyle.title}`}>{props.pageContext.frontmatter.title}</h1>
                    {date}
                </div>
                {sectionBox}
                <div className={`${layoutStyle.mdxbox} text-serif`}>
                    <MDXProvider components={shortcodes}>
                        { props.children }
                    </MDXProvider>
                </div>

                <div className={`text-sans-serif-display`}>
                    <div style={{display: "block"}} className={`${layoutStyle.linkbox}`}>
                        <table>
                            <tbody>
                                <tr>
                                    <td>{prevlink}</td>
                                    <td>{nextlink}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div style={{textAlign: 'center'}}>
                        <Link to='/blog' className={`${layoutStyle.link}`}><span style={{fontFamily: 'sans-serif'}}>&uarr;</span> Back to Blog</Link>
                    </div>
                </div>
            </div>
            
            
        </Frame>
    );  
}

// figure out how to make a static query to get 
// the slugs and titles of next and previous articles
